import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
	{
		path: '/',
		component: () => import('../views/Login.vue'),
	},
	{
		path: '/home',
		name: 'name',
		redirect: '/welcome',
		component: () => import('../views/Home.vue'),
		children: [
			{
				path: '/welcome',
				component: () => import('../components/welcome.vue'),
			},
			{
				path: '/rights',
				component: () => import('../components/privilege.vue'),
			},
			{
				path: '/systemUser',
				component: () => import('../components/systemUser.vue'),
			},
			{
				path: '/menu',
				component: () => import('../components/menu.vue'),
			},
			{
				path: '/employee',
				component: () => import('../components/employee.vue'),
			},
			{
				path: '/parcel',
				component: () => import('../components/parcel.vue'),
			},
			{
				path: '/access',
				component: () => import('../components/access.vue'),
			},
			{
				path: '/site',
				component: () => import('../components/site.vue'),
			},
			{
				path: '/vehicle',
				component: () => import('../components/vehicle.vue'),
			},
			{
				path: '/vehicleTracking',
				component: () => import('../components/vehicleTracking.vue'),
			},
			{
				path: '/cloudServices',
				component: () => import('../components/cloudServices.vue'),
			},
			{
				path: '/courier',
				component: () => import('../components/courier.vue'),
			},
			{
				path: '/mapDetail',
				component: () => import('../components/mapDetail.vue'),
			},
		],
	},
]

const router = new VueRouter({
	routes,
})
// 挂载守卫
router.beforeEach((to, from, next) => {
	// addRoutesData()
	if (to.path === '/') {
		return next()
	}
	// 获取token
	const tokenStr = window.sessionStorage.getItem('token')
	if (!tokenStr) return next('/home')
	next()
})

// function addRoutesData() {
// 	//根据数据动态生成路由
// 	getNav().then((res) => {
// 		routes.children = addDynamicRoutes(res.data.data)
// 		// console.log(routes.children)
// 		// store.dispatch('SETNAV', res.data.result)
// 		//缓存数据
// 		router.addRoute('home', routes.children)
// 		//动态添加路由
// 	})
// }
//路由数据拼接
// function addDynamicRoutes(data) {
// 	data.forEach((item) => {
// 		item.subMenu.forEach((i) => {
// 			// console.log(routes.children[1])
// 			routes.children.push({
// 				path: `/${i.menuUrl}`,
// 				component: () => import(`../components/${i.menuUrl}`),
// 			})
// 		})
// 	})
// 	return routes
// }
export default router

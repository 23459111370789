import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store/index.js'

// 引入的element组件
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
Vue.use(ElementUI)

// 引入axios
import axios from 'axios'
import VueAxios from 'vue-axios'
Vue.use(VueAxios, axios)

// 创建 axios 请求实例,配置请求根路径
const instance = axios.create({
	baseURL: '/api',
	timeout: 1000,
	headers: { 'Content-Type': 'application/json;charset=UTF-8' ,},
})

// 添加请求拦截器
instance.interceptors.request.use(
	(config) => {
		config.headers.Authorization = window.sessionStorage.getItem('token')
		return config
	},
	function (error) {
		// 对请求错误做些什么
		return Promise.reject(error)
	}
)

Vue.config.productionTip = false

new Vue({
	store,
	router,
	render: function (h) {
		return h(App)
	},
}).$mount('#app')
